import React from "react"

import BlockContainer from "../components/block-container"
import ContentfulFooterBlock from "../components/contentful-footer-block"
import ContentfulHeroBlock from "../components/contentful-hero-block"
import ContentfulIndustriesBlock from "../components/contentful-industries-block"
import ContentfulProjectsBlock from "../components/contentful-projects-block"
import ContentfulServicesBlock from "../components/contentful-services-block"
import Hubspot from "../components/form-hubspot"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const HomePagePage = ({
  pageContext,
}) => {
  const homePage = pageContext.node

  return (
    <>
     <GatsbySeo noindex={false} canonical="https://www.obviouslee.com" />
      <SEO
        description={homePage.metaDescription?.metaDescription}
        title={homePage.metaTitle}

      />
      <Layout headerLabel={homePage.headerLabel}>
        <ContentfulHeroBlock
          headline={homePage.heroHeadline?.heroHeadline}
          px={5}
        />
        <ContentfulIndustriesBlock
          industry1Description={
            homePage.industry1Description?.industry1Description
          }
          industry1Name={homePage.industry1Name}
          industry1Tags={homePage.industry1Tags}
          industry2Description={
            homePage.industry2Description?.industry2Description
          }
          industry2Name={homePage.industry2Name}
          industry2Tags={homePage.industry2Tags}
          industry3Description={
            homePage.industry3Description?.industry3Description
          }
          industry3Name={homePage.industry3Name}
          industry3Tags={homePage.industry3Tags}
          industry4Description={
            homePage.industry4Description?.industry4Description
          }
          industry4Name={homePage.industry4Name}
          industry4Tags={homePage.industry4Tags}
        />
        <ContentfulProjectsBlock
          projects={homePage.projects}
        />
        <ContentfulServicesBlock
          services={homePage.services?.services}
        />
        <BlockContainer>
          <Hubspot forms={homePage.hubspotForms} uuid="home" />
        </BlockContainer>
        <ContentfulFooterBlock
          address={homePage.footer?.address?.address}
          backgroundColor={homePage.footer?.backgroundColor}
          copyright={homePage.footer?.copyright?.copyright}
          socialLinksTitle={homePage.footer?.socialLinksTitle}
          blogTitle={homePage.footer?.blogTitle}
          newsletterJoinTitle={homePage.footer?.newsletterJoinTitle}
          facebookLink={homePage.footer?.facebookLink}
          form={homePage.footer?.form}
          gif={homePage.footer?.gIf}
          gifDescription={homePage.footer?.gifDescription}
          instagramLink={homePage.footer?.instagramLink}
          linkedInLink={homePage.footer?.linkedInLink}
        />
      </Layout>
    </>
  )
}

export default HomePagePage
